<template>
  <div class="h5pay">
    <div class="loading" v-if="loading">
      <van-loading size="32px" color="#18b09f">支付中...</van-loading>
    </div>
    <div class="pay-wrap">
      <div class="pay-detail">
        <div class="title">
          {{ doctorName != "noDoctorName" ? doctorName : "" }}{{ title
          }}{{ doctorName != "noDoctorName" ? "费" : "" }}
        </div>
        <div class="price">¥{{ payFee }}</div>
      </div>
      <div class="pay-wrap">
        <div class="pay-title">支付方式</div>
        <div class="pay-content">
          <div
            class="pay-item"
            v-if="type != 'wechat'"
            @click="chooseType = 'alipay'"
          >
            <div class="pay-info">
              <img src="../../assets/img/alipay.png" class="pay-logo" />
              <span class="pay-text">支付宝支付</span>
            </div>
            <div>
              <img
                src="../../assets/img/chooseGreen.png"
                v-if="chooseType == 'alipay'"
                class="choose-pic"
              />
              <img
                src="../../assets/img/noChoose.png"
                v-else
                class="choose-pic"
              />
            </div>
          </div>
          <div
            class="pay-item"
            v-if="type != 'alipay'"
            @click="chooseType = 'wechat'"
          >
            <div class="pay-info">
              <img src="../../assets/img/wechat.png" class="pay-logo" />
              <span class="pay-text">微信支付</span>
            </div>
            <div>
              <img
                src="../../assets/img/chooseGreen.png"
                v-if="chooseType == 'wechat'"
                class="choose-pic"
              />
              <img
                src="../../assets/img/noChoose.png"
                v-else
                class="choose-pic"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="btn-bottom">
        <div class="price">¥{{ payFee }}</div>
        <div class="sure" @click="surePay()">确认支付</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      appId: this.$route.params.appId,
      payOrderId: this.$route.params.payOrderId,
      doctorName: this.$route.params.doctorName,
      type: "",
      title: this.$route.params.title,
      payFee: this.$route.params.payFee,
      loading: false,
      chooseType: "alipay",
    };
  },
  methods: {
    surePay() {
      this.loading = true;
      if (this.chooseType == "alipay") {
        this.$axios
          .get(
            `/ms-pocket-hospital/v1/aliPay/h5?appId=${this.appId}&payOrderId=${this.payOrderId}`,
            { basurlType: "useslhospital" }
          )
          .then((res) => {
            this.loading = false;
            const div = document.createElement("div");
            div.innerHTML = res.data.results.form;
            document.body.appendChild(div);
            document.forms[0].submit();
          });
      } else {
        this.$axios
          .get(
            `/ms-pocket-hospital/v1/wechat/h5?appId=wx78b521608475e878&payOrderId=${this.payOrderId}`
          )
          .then((res) => {
            this.loading = false;
            // alert(res.data.results.mwebUrl);
            window.location.href = res.data.results.mwebUrl;
          });
      }
    },
  },
  created() {
    console.log(this.$route.params, "params");
    this.type = this.$route.query.type ? this.$route.query.type : "";
    if (this.type == "wechat") {
      this.chooseType = "wechat";
    } else {
      this.chooseType = "alipay";
    }
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F8F9FA");
  },
};
</script>
<style lang="scss">
.h5pay {
  .loading {
    width: 100%;
    position: absolute;
    top: 40%;
    text-align: center;
  }
  .pay-detail {
    background-color: #fff;
    display: flex;
    display: -webkit-flex;
    margin-bottom: 40px;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: center;
    -webkit-align-items: center;
    height: 270px;
    padding: 0 40px;
    .title {
      flex: 1;
      -webkit-box-flex: 1;
      color: #333333;
      font-size: 32px;
      padding-right: 40px;
    }
    .price {
      font-size: 36px;
      color: #fa6400;
    }
  }
  .pay-wrap {
    padding: 20px 0 40px;
  }
  .pay-title {
    padding: 0 40px 30px;
    font-size: 32px;
    line-height: 32px;
    color: #333;
  }
  .pay-content {
    background-color: #fff;
    padding: 0 26px;
  }
  .pay-item {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: center;
    -webkit-align-items: center;
    padding: 20px 2px;
    border-bottom: 2px solid #ececec;
    &:last-child {
      border-bottom: none;
    }
  }
  .pay-logo {
    height: 52px;
    vertical-align: middle;
  }
  .choose-pic {
    height: 40px;
  }
  .pay-text {
    vertical-align: middle;
    margin-left: 20px;
    font-size: 28px;
    color: #333;
  }
  .btn-bottom {
    text-align: left;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 -2px 8px 0 rgba(73, 105, 97, 0.14);
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    .price {
      flex: 1;
      -webkit-box-flex: 1;
      padding-left: 40px;
      font-size: 36px;
      color: #fa6400;
    }
    .sure {
      padding: 30px 80px;
      font-size: 32px;
      color: #fff;
      line-height: 44px;
      background: #19b09c;
    }
  }
}
</style>
